// const FirebaseConfig = {
//   apiKey: "AIzaSyC107UFBsKfkQVGW_Ztmvrq4eDYSsm3JMU",
//   authDomain: "migration-ui.firebaseapp.com",
//   projectId: "migration-ui",
//   storageBucket: "migration-ui.appspot.com",
//   messagingSenderId: "562833283664",
//   appId: "1:562833283664:web:0fdf4c9677c6277c350f55",
//   measurementId: "G-HRNKJY7ZGG"
// }

const FirebaseConfig = {
  apiKey: "AIzaSyCOinwhTiTouJ6b-o8W9WnzelHO9Z7fqeg",
  authDomain: "aatma-studio-react.firebaseapp.com",
  projectId: "aatma-studio-react",
  storageBucket: "aatma-studio-react.appspot.com",
  messagingSenderId: "880329098412",
  appId: "1:880329098412:web:f60dc2ebaad1c6c1fc0b09",
  measurementId: "G-8N5H316SRP"
}

export default FirebaseConfig

