import { DashboardOutlined, TeamOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "apps-tenant-clients",
    path: `${APP_PREFIX_PATH}/clients`,
    title: "sidenav.apps.clients",
    breadcrumb: true,
    icon: TeamOutlined,
    submenu: [],
  },
  {
    key: "apps-teams",
    path: `${APP_PREFIX_PATH}/teams`,
    title: "sidenav.apps.teams",
    breadcrumb: true,
    icon: ThunderboltOutlined,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
